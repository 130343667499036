import { mutationStore, queryStore } from '@urql/svelte';

import { client } from './client';
import {
  AcceptTermsDocument,
  type AcceptTermsMutation,
  type AcceptTermsMutationVariables,
  type AcceptTermsResultInput,
  CancelSubscriptionDocument,
  type CancelSubscriptionMutation,
  type CancelSubscriptionMutationVariables,
  GetProfileDocument,
  type GetProfileQuery,
  type GetProfileQueryVariables,
} from './generated';

export const createProfileStore = (
  { pause }: { pause?: boolean } = { pause: false },
) => {
  const qs = queryStore<GetProfileQuery, GetProfileQueryVariables>({
    client,
    query: GetProfileDocument,
    pause,
  });

  return {
    ...qs,
    refresh: () => {
      qs.reexecute({});
    },
  };
};

export const acceptTerms = (input: AcceptTermsResultInput) => {
  return mutationStore<AcceptTermsMutation, AcceptTermsMutationVariables>({
    client,
    query: AcceptTermsDocument,
    variables: {
      input,
    },
  });
};
