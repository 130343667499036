const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const getUserIdFromToken = (token: string): string | null => {
  const body = parseJwt(token);
  return body?.uid ?? body?.user_id ?? null;
};
