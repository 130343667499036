import { initializeApp } from 'firebase/app';

import { ENV } from '../config/environment';

export default class FirebaseService {
  public static init = async () => {
    return await Promise.all([
      this.initSpeechifyMobile(),
      this.initVideoStudio(),
    ]);
  };
  private static initSpeechifyMobile() {
    const app = initializeApp(
      ENV.firebaseSDK.speechifyMobileConfig,
      'Speechify Mobile',
    );
    return app;
  }

  private static async initVideoStudio() {
    const app = initializeApp(
      ENV.firebaseSDK.videoStudioConfig,
      'Video Studio',
    );
    return app;
  }
}
