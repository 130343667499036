import { Configuration, CustomTokensApi } from '@speechifyinc/auth-sdk';
import {
  getAuth,
  GoogleAuthProvider,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  type User,
} from 'firebase/auth';

import { ENV } from '../config/environment';
import { getCurrentFirebaseStore } from '../stores/firebaseStore';
import { waitForUserAuth } from '../stores/user';
import { getUserIdFromToken } from '../utils/jwt';

export default class FirebaseAuthService {
  static customTokenApi = new CustomTokensApi(
    new Configuration({
      basePath: ENV.authApiUrl,
    }),
  );

  /**
   * sign in to video studio firebase app, using a speechify mobile user
   * @param User (user) - the speechify mobile user
   */
  public static async signIntoVideoStudio(speechifyMobileUser: User) {
    const firebaseStore = getCurrentFirebaseStore();
    const auth = getAuth(firebaseStore.videoStudio);
    const { projectId } = ENV.firebaseSDK.videoStudioConfig;
    const idToken = await speechifyMobileUser.getIdToken();
    const response = await this.customTokenApi.customTokenControllerCreateToken(
      {
        createCustomTokenDto: {
          projectId,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
          'Content-Type': 'application/json',
        },
      },
    );

    const { token } = response;

    if (token) {
      await signInWithCustomToken(auth, token);
    } else {
      console.log('Error: Invalid Token From Cross Auth');
    }
  }

  /**
   * Sign in with Google using the Speechify Mobile Firebase App,
   *
   * NOTE: this will trigger the onAuthStateChanged listener in the
   *       authStore and singIn with Custom Token in the Video Studio App
   */
  public static async signInWithGoogle() {
    const firebaseStore = getCurrentFirebaseStore();
    const provider = new GoogleAuthProvider();
    if (!firebaseStore.speechifyMobile) {
      // TODO: Throw Error
      console.log(
        "Can't sign in with Google, firebaseStore.speechifyMobile is null",
      );
      return;
    }
    const speechifyMobileAuth = getAuth(firebaseStore.speechifyMobile);
    await signInWithPopup(speechifyMobileAuth, provider);
  }

  /**
   * Sign in with email and password using the Speechify Mobile Firebase App,
   *
   * NOTE: this will trigger the onAuthStateChanged listener in the
   *       authStore and signIn with Custom Token in the Video Studio App
   * @param email the email of the user
   * @param password password of the user
   */
  public static async signInWithEmail(email: string, password: string) {
    const firebaseStore = getCurrentFirebaseStore();
    if (!firebaseStore.speechifyMobile) {
      // TODO: Throw Error
      console.log(
        "Can't sign in with email password, firebaseStore.speechifyMobile is null",
        firebaseStore,
      );
      return;
    }
    const speechifyMobileAuth = getAuth(firebaseStore.speechifyMobile);
    await signInWithEmailAndPassword(speechifyMobileAuth, email, password);
  }

  /**
   * Sign in with custom token using the Speechify Mobile Firebase App,
   *
   * NOTE: this will trigger the onAuthStateChanged listener in the
   *      authStore and signIn with Custom Token in the Video Studio Apps will be triggered.
   * @param token the custom token
   */
  public static async signInWithCustomToken(token: string) {
    const firebaseStore = getCurrentFirebaseStore();
    if (!firebaseStore.speechifyMobile) {
      // TODO: Throw Error
      console.log(
        "Can't sign in with email password, firebaseStore.speechifyMobile is null",
        firebaseStore,
      );

      return;
    }
    const speechifyMobileAuth = getAuth(firebaseStore.speechifyMobile);
    await signInWithCustomToken(speechifyMobileAuth, token);
  }

  public static async signInWithSessionCookie() {
    const firebaseStore = getCurrentFirebaseStore();
    if (!firebaseStore.speechifyMobile) {
      {
        throw new Error(
          "Can't sign in with session cookie firebaseStore.speechifyMobile is null",
        );
      }
    }

    try {
      const res = await this.customTokenApi.customTokenControllerCreateToken(
        {
          createCustomTokenDto: {
            projectId: ENV.firebaseSDK.speechifyMobileConfig.projectId,
          },
        },
        {
          credentials: 'include',
        },
      );
      if (res.token) {
        const uid = getUserIdFromToken(res.token);
        await this.signInWithCustomToken(res.token);
        await waitForUserAuth(uid);
        return true;
      }
    } catch (error) {
      console.error('Error signing in with session cookie:', error);
    }
    return false;
  }
}
