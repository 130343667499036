import type { FirebaseApp } from 'firebase/app';
import { writable } from 'svelte/store';

import FirebaseService from '../services/firebase.service';

type FirebaseStore = {
  speechifyMobile: FirebaseApp | null;
  videoStudio: FirebaseApp | null;
};

const firebaseStore = writable<FirebaseStore>({
  speechifyMobile: null,
  videoStudio: null,
});

export const getCurrentFirebaseStore = () => {
  let currentFirebaseStore = null;
  const unsubscribe = firebaseStore.subscribe((value) => {
    currentFirebaseStore = value;
  });
  unsubscribe();
  return currentFirebaseStore;
};

export default {
  subscribe: firebaseStore.subscribe,
  init: () => {
    FirebaseService.init().then(([speechifyMobile, videoStudio]) => {
      firebaseStore.set({
        speechifyMobile,
        videoStudio,
      });
    });
  },
};
